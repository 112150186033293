import React from "react";
import PropTypes from "prop-types";
import { Heading, List, ListItem, Stack, Link } from "@chakra-ui/react";
import Captions from "file-loader!./webvtt.vtt";

const DataLine = (data) => {
  const { resourceType, description, doi, name, url } = data.data;

  if (!doi) {
    return null;
  }

  const dataLine = (
    <>
      Description: {description}. Resource Type: {resourceType}. DOI:{" "}
      <Link href={`https://doi.org/${doi}`} isExternal>
        {doi}
      </Link>
    </>
  );

  if (resourceType === "Audiovisual" && name.split(".").pop() === "mp4") {
    return (
      <ListItem>
        {dataLine}
        <video controls>
          <source src={url} type="video/mp4" />
          <track kind="captions" srcLang="en" src={Captions} />
        </video>
      </ListItem>
    );
  }
  return <ListItem>{dataLine}</ListItem>;
};

const ExtendedData = (props) => {
  const { extendedData } = props;

  const dataLines = extendedData.map((data, index) => {
    return <DataLine data={data} key={`extended-data-${index}`} />;
  });

  return (
    <Stack marginBottom="1rem">
      <Heading size="lg" fontWeight="normal" fontFamily="Noto sans, sans-serif">
        Extended Data
      </Heading>
      <List fontFamily="Noto serif, serif">{dataLines}</List>
    </Stack>
  );
};

ExtendedData.propTypes = {
  extendedData: PropTypes.arrayOf(
    PropTypes.shape({
      resourceType: PropTypes.string,
      description: PropTypes.string,
      doi: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default ExtendedData;
