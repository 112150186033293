import React from "react";
import PropTypes from "prop-types";
import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/react";

const Proteopedia = (props) => {
  const { proteopediaLink, showProteopedia, setShowProteopedia } = props;

  if (!proteopediaLink) return null;

  return (
    <>
      <Modal
        onClose={() => setShowProteopedia(false)}
        size="full"
        isOpen={showProteopedia}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading
              size="lg"
              fontWeight="normal"
              fontFamily="Noto sans, sans-serif"
            >
              Proteopedia Molecular Tour
            </Heading>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <iframe
              title="Proteopedia Molecular Tour"
              width="100%"
              height="526px"
              src={proteopediaLink}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

Proteopedia.propTypes = {
  proteopediaLink: PropTypes.string.isRequired,
  showProteopedia: PropTypes.bool.isRequired,
  setShowProteopedia: PropTypes.func.isRequired,
};

export default Proteopedia;
