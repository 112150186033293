import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, Stack, Heading } from "@chakra-ui/react";

const formatDate = (date) => {
  if (date) {
    const jsDate = new Date(date);
    return `${
      jsDate.getUTCMonth() + 1
    }/${jsDate.getUTCDate()}/${jsDate.getUTCFullYear()}`;
  }
  return "Not set";
};

const History = (props) => {
  const { history } = props;

  if (!history) return null;
  const { received, revisionReceived, accepted, published, indexed } = history;

  const today = new Date();
  const showIndexed = indexed && new Date(indexed) < today;

  return (
    <Stack>
      <Heading size="lg" fontWeight="normal" fontFamily="Noto sans, sans-serif">
        History
      </Heading>

      <List>
        {received && <ListItem>Received: {formatDate(received)}</ListItem>}
        {revisionReceived && (
          <ListItem>Revision Received: {formatDate(revisionReceived)}</ListItem>
        )}
        {accepted && <ListItem>Accepted: {formatDate(accepted)}</ListItem>}
        {published && !indexed && (
          <ListItem>Published: {formatDate(published)}</ListItem>
        )}
        {published && indexed && (
          <ListItem>Published Online: {formatDate(published)}</ListItem>
        )}
        {showIndexed && <ListItem>Indexed: {formatDate(indexed)}</ListItem>}
      </List>
    </Stack>
  );
};

History.propTypes = {
  history: PropTypes.shape({
    received: PropTypes.string,
    revisionReceived: PropTypes.string,
    accepted: PropTypes.string,
    published: PropTypes.string,
  }).isRequired,
};

export default History;
