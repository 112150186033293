import React from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Link,
} from "@chakra-ui/react";
import { Markup } from "interweave";
import TextBlock from "./TextBlock";

const Figure = (props) => {
  const { figure, title, caption, isFigureOpen, setIsFigureOpen } = props;

  if (!figure) return null;

  const toggleOpen = () => {
    setIsFigureOpen(!isFigureOpen);
  };

  return (
    <>
      <Link id="figure" />
      <Stack>
        <Image src={figure} onClick={toggleOpen} cursor="zoom-in" />
        {title && title !== "<p></p>" && (
          <Markup
            content={`<b>${
              title.startsWith("Figure") ? "" : "Figure 1."
            } ${title
              .replace("<p>", "")
              .replace("</p>", "")
              .replace(":", "")
              .replace(".", "")}:</b>`}
          />
        )}
        {(!title || title === "<p></p>") && <b>Figure 1.</b>}
        {caption && <TextBlock text={caption} isHtml />}
      </Stack>
      <Modal
        onClose={toggleOpen}
        size="full"
        isOpen={isFigureOpen}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          {title ? (
            <ModalHeader>
              <Markup content={title} />
            </ModalHeader>
          ) : (
            "Figure 1."
          )}
          <ModalCloseButton />
          <ModalBody>
            <Image src={figure} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

Figure.propTypes = {
  figure: PropTypes.string.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
};

Figure.defaultProps = {
  title: null,
  caption: null,
};

export default Figure;
