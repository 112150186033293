import React from "react";
import PropTypes from "prop-types";
import { Heading, List, ListItem, Stack } from "@chakra-ui/react";

const creditMap = {
  conceptualization: "Conceptualization",
  dataCuration: "Data curation",
  formalAnalysis: "Formal analysis",
  fundingAcquisition: "Funding acquisition",
  investigation: "Investigation",
  methodology: "Methodology",
  project: "Project administration",
  resources: "Resources",
  software: "Software",
  supervision: "Supervision",
  validation: "Validation",
  visualization: "Visualization",
  writing_originalDraft: "Writing - original draft",
  writing_reviewEditing: "Writing - review & editing",
};

const Contribution = (props) => {
  const { author } = props;
  const { firstName, lastName, credit } = author;

  const creditString = credit
    .map((c) => (creditMap[c] ? creditMap[c] : c))
    .join(", ");

  return (
    <ListItem>
      {firstName} {lastName}: {creditString}
    </ListItem>
  );
};

const Contributions = (props) => {
  const { authors } = props;

  if (!authors || authors.length === 0) return null;

  const credits = authors
    .map((author, index) => {
      if (
        !author.credit ||
        author.credit.length === 0 ||
        author.credit[0] === ""
      )
        return null;

      return <Contribution author={author} key={`contribution-${index}`} />;
    })
    .filter((c) => c);

  if (!credits || credits.length === 0) return null;
  return (
    <Stack marginBottom="1rem">
      <Heading size="lg" fontWeight="normal" fontFamily="Noto sans, sans-serif">
        Author Contributions
      </Heading>
      <List fontFamily="Noto serif, serif">{credits}</List>
    </Stack>
  );
};

Contributions.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      credit: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default Contributions;
