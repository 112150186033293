import React from "react";
import { graphql } from "gatsby";

import Article from "../ui/article/Article";
import Layout from "../components/Layout";

const transformData = (data) => {
  const {
    citations,
    doi,
    history,
    submissionTypes,
    id,
    dbReferenceId,
    pmcId,
    pmId,
    proteopedia,
    reviewPanel,
    species,
    corrections,
    integrations,
    versions,
  } = data;

  const reviewers = versions.map((v) =>
    v.reviews.map((r) => {
      if (r.status.submitted) {
        return r.openAcknowledgement ? r.reviewer.displayName : "Anonymous";
      }
      return null;
    })
  );

  const curators = versions.map((v) =>
    v.curatorReviews.map((r) => {
      if (r.submitted) {
        return r.openAcknowledgement ? r.curator.displayName : "Anonymous";
      }
      return null;
    })
  );

  const version = versions
    .filter((v) => v.decision === "publish")
    .map((v) => ({
      title: v.title,
      authors: v.authors,
      abstract: v.abstract,
      image: v.image,
      imageTitle: v.imageTitle,
      imageCaption: v.imageCaption,
      patternDescription: v.patternDescription,
      methods: v.methods,
      reagents: v.reagents,
      references: v.references,
      acknowledgements: v.acknowledgements,
      funding: v.funding,
      extendedData: v.extendedData,
      awards: v.awards,
    }))
    .pop();

  const transformedIntegrations = integrations.map((integration) => {
    const { doi: integrationDoi, title } = integration;

    const articleId = integrationDoi
      .split("/")
      .pop()
      .replace(/\./g, "-")
      .toLowerCase();

    return { articleId, title };
  });

  const article = {
    citations,
    doi,
    history,
    submissionTypes,
    id,
    integrations: transformedIntegrations,
    dbReferenceId,
    pmcId,
    pmId,
    proteopedia,
    species,
    corrections,
    reviewers,
    reviewPanel,
    version,
    curators,
  };

  return article;
};

const ArticlePage = ({ data, pageContext }) => {
  const transformedData = transformData(data.article.manuscript);

  const article = (
    <Article
      article={transformedData}
      speciesTaxonomy={data.species.species}
      citedBy={pageContext.citedBy}
    />
  );

  const { doi, history, version } = transformedData;
  const { title, authors } = version;
  const titleNoHtml = title.replace(/(<([^>]+)>)/gi, "");

  const articleId = doi.split("/").pop().replace(/\./g, "-").toLowerCase();

  const publicationDate = new Date(history.published);
  const metaTags = [
    { name: "citation_title", content: titleNoHtml },
    {
      name: "citation_publication_date",
      content: `${publicationDate.getFullYear()}/${
        publicationDate.getMonth() + 1
      }/${publicationDate.getDay()}`,
    },
    { name: "citation_journal_title", content: "microPublication Biology" },
    { name: "citation_publisher", content: "Caltech Library" },
    {
      name: "citation_pdf_url",
      content: `https://micropublication.org/static/pdf/${articleId}.pdf`,
    },
    {
      name: "citation_doi",
      content: doi,
    },
  ];

  authors.forEach((author) => {
    metaTags.push({
      name: "citation_author",
      content: `${author.lastName}, ${author.firstName}`,
    });
  });

  return (
    <Layout
      children={article}
      title={`${titleNoHtml} | microPublication`}
      metaTags={metaTags}
    />
  );
};

export const query = graphql`
  query($id: ID!) {
    article: micropubApp {
      manuscript(id: $id) {
        id
        submissionTypes
        citations
        doi
        dbReferenceId
        pmcId
        pmId
        proteopedia
        reviewPanel
        species
        integrations {
          title
          doi
        }
        corrections {
          doi
          description
          correctionType
          date
        }
        history {
          received
          revisionReceived
          accepted
          published
          indexed
        }
        versions {
          id
          decision
          abstract
          acknowledgements
          authors {
            affiliations
            departments
            credit
            email
            firstName
            lastName
            submittingAuthor
            correspondingAuthor
            equalContribution
            WBId
            orcid
          }
          awards {
            awardId
            funderName
            awardRecipient
          }
          extendedData {
            description
            doi
            resourceType
            name
            url
          }
          funding
          image {
            url
          }
          imageCaption
          imageTitle
          methods
          reagents
          patternDescription
          references {
            reference
            pubmedId
            doi
          }
          title
          reviews {
            reviewer {
              displayName
            }
            openAcknowledgement
            status {
              submitted
            }
          }
          curatorReviews {
            curator {
              displayName
            }
            openAcknowledgement
            submitted
          }
        }
      }
    }
    species: micropubApp {
      species {
        value
        label
        imageSrc
        imageAlt
        mod
        modLink
        linkVariable
      }
    }
  }
`;

export default ArticlePage;
