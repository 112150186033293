import React, { useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverBody,
  PopoverContent,
  Link,
  PopoverFooter,
  ButtonGroup,
  Tooltip,
  Icon,
  LinkOverlay,
  LinkBox,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  DownloadIcon,
  CopyIcon,
  EmailIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import { Link as GatsbyLink } from "gatsby";
import { Markup } from "interweave";
import { FaTwitterSquare } from "react-icons/fa";

const ArticleMenu = (props) => {
  const [copied, setCopied] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const [citeOpen, setCiteOpen] = useState(false);
  const {
    submissionTypes,
    title,
    doi,
    pmcId,
    pmId,
    species,
    citation,
    onClickShowProteopedia,
    showProteopediaButton,
    citedBy,
  } = props;
  const transformedTitle = title.replace(/(<([^>]+)>)/gi, "");
  const articleId = doi && doi.split("/").pop().replace(/\./g, "-");

  const copyCitation = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(citation).then(
        () => {
          setCiteOpen(false);
          setCopyMessage("Copied to clipboard");
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        },
        () => {
          setCopyMessage("Failed to Copy to clipboard");
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }
      );
    } else {
      // text area method
      const textArea = document.createElement("textarea");
      textArea.value = citation;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      // textArea.focus();
      textArea.select();
      new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      }).then(
        () => {
          setCopyMessage("Copied to clipboard");
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        },
        () => {
          setCopyMessage("Failed to Copy to clipboard");
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }
      );
    }
  };

  return (
    <Flex flexWrap="wrap" justifyContent="space-between">
      {submissionTypes && (
        <>
          {submissionTypes.map((type, index) => (
            <GatsbyLink
              key={`type-${index}`}
              to={`/journals/biology/types/${type
                .toLowerCase()
                .replace(" ", "%20")}`}
            >
              <Button
                colorScheme={
                  type.toLowerCase() === "retracted" ||
                  type.toLowerCase() === "retraction"
                    ? "red"
                    : "teal"
                }
                size="sm"
              >
                {type.toUpperCase()}
              </Button>
            </GatsbyLink>
          ))}
        </>
      )}

      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant="outline"
        >
          Share
        </MenuButton>
        <MenuList>
          <LinkBox as={MenuItem}>
            <LinkOverlay
              href={`https://twitter.com/intent/tweet?text=${transformedTitle}&url=https://doi.org/${doi}`}
              isExternal
            >
              Twitter
              <Icon as={FaTwitterSquare} marginLeft="5px" />
            </LinkOverlay>
          </LinkBox>
          <LinkBox as={MenuItem}>
            <LinkOverlay
              href={`mailto:?subject=${transformedTitle}&body=I%20want%20to%20share%20this%20interesting%20article:%20https://doi.org/${doi}`}
              isExternal
            >
              Email
              <EmailIcon marginLeft="5px" />
            </LinkOverlay>
          </LinkBox>
        </MenuList>
      </Menu>

      <Popover
        isOpen={citeOpen}
        onOpen={() => setCiteOpen(true)}
        onClose={() => setCiteOpen(false)}
      >
        <PopoverTrigger>
          <Button variant="outline" rightIcon={<ChevronDownIcon />}>
            <Tooltip label={copyMessage} isOpen={copied}>
              Cite
            </Tooltip>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader>Citation</PopoverHeader>
          <PopoverBody>
            <Markup content={citation} />
          </PopoverBody>
          <PopoverFooter>
            <ButtonGroup size="sm">
              <Button onClick={copyCitation} rightIcon={<CopyIcon />}>
                Copy
              </Button>
              <Button
                as={Link}
                href={`https://data.crosscite.org/application/x-bibtex/${doi}`}
                isExternal
                rightIcon={<DownloadIcon />}
              >
                BibTeX
              </Button>
              <Button
                as={Link}
                href={`https://data.crosscite.org/application/x-research-info-systems/${doi}`}
                isExternal
                rightIcon={<DownloadIcon />}
              >
                RIS
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>

      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant="outline"
        >
          Download
        </MenuButton>
        <MenuList>
          <LinkBox as={MenuItem}>
            <LinkOverlay href={`/static/pdf/${articleId}.pdf`} isExternal>
              PDF
              <DownloadIcon marginLeft="5px" />
            </LinkOverlay>
          </LinkBox>
          <LinkBox as={MenuItem}>
            <LinkOverlay href={`/static/jats/${articleId}.xml`} isExternal>
              XML
              <DownloadIcon marginLeft="5px" />
            </LinkOverlay>
          </LinkBox>
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant="outline"
        >
          Links
        </MenuButton>
        <MenuList>
          {species &&
            species.map((s) => (
              <LinkBox as={MenuItem} key={`species-${s.mod}`}>
                <LinkOverlay href={s.modLink} isExternal>
                  {s.mod}
                </LinkOverlay>
              </LinkBox>
            ))}

          <LinkBox as={MenuItem}>
            {pmId && (
              <LinkOverlay
                href={`https://pubmed.ncbi.nlm.nih.gov/${pmId}`}
                isExternal
              >
                PubMed ({pmId})
                <ExternalLinkIcon marginLeft="5px" />
              </LinkOverlay>
            )}
            {!pmId && <>PubMed ID is not currently available</>}
          </LinkBox>
          <LinkBox as={MenuItem}>
            {pmcId && (
              <LinkOverlay
                href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${pmcId}`}
                isExternal
              >
                PubMed Central ({pmcId})
                <ExternalLinkIcon marginLeft="5px" />
              </LinkOverlay>
            )}
            {!pmcId && <>PubMed Central ID is not currently available</>}
          </LinkBox>
          {pmId && (
            <LinkBox as={MenuItem}>
              <LinkOverlay
                href={`http://europepmc.org/article/MED/${pmId}`}
                isExternal
              >
                Europe PubMed Central
                <ExternalLinkIcon marginLeft="5px" />
              </LinkOverlay>
            </LinkBox>
          )}
        </MenuList>
      </Menu>
      {citedBy && citedBy.length > 0 && (
        <>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="outline"
            >
              Cited By ({citedBy.length})
            </MenuButton>
            <MenuList>
              {citedBy.map((cite) => (
                <LinkBox as={MenuItem}>
                  <LinkOverlay href={cite.url} isExternal>
                    {cite.firstAuthor} et al,{" "}
                    {cite.datePublished.substring(
                      cite.datePublished.length - 4
                    )}
                    , {cite.journal}
                    <ExternalLinkIcon marginLeft="5px" />
                  </LinkOverlay>
                </LinkBox>
              ))}
            </MenuList>
          </Menu>
        </>
      )}
      {showProteopediaButton && (
        <>
          <Button onClick={onClickShowProteopedia} variant="outline">
            Proteopedia Molecular Tour
          </Button>
        </>
      )}
    </Flex>
  );
};

export default ArticleMenu;
