import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, Stack, Text } from "@chakra-ui/react";
import { Link } from "gatsby";
import { capitalize } from "lodash";

const formatDate = (date) =>
  date ? new Date(date).toLocaleDateString() : "Not set";

const Corrections = (props) => {
  const { corrections } = props;

  if (!corrections || corrections.length === 0) return null;

  return (
    <Stack borderWidth="1px" borderRadius="lg" boxShadow="md" padding="10px">
      <Text fontWeight="bold">This article has a correction:</Text>
      <List>
        {corrections.map((correction) => {
          const { correctionType, doi, date } = correction;
          const correctionId =
            doi && doi.includes("/") && doi.split("/")[1].replace(/\./g, "-");
          return (
            <ListItem>
              <u>
                <Link to={`/journals/biology/${correctionId}`}>
                  {capitalize(correctionType)} - {formatDate(date)}
                </Link>
              </u>
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
};

Corrections.propTypes = {
  corrections: PropTypes.arrayOf(
    PropTypes.shape({
      correctionType: PropTypes.string,
      doi: PropTypes.string,
      date: PropTypes.string,
    })
  ),
};

export default Corrections;
